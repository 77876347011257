<template>
  <div class="mynft">
    <div class="flex_column flex_items gogo">
      <p class="f34 mt20" style="color: #dbdbdb">NFT Wallet</p>
      <p class="mt10 c6 f14">Address:</p>
      <div
        class="f14 flex_items pos f-center mt10"
        style="color: #9b9b9b; width: 240px; word-wrap: break-word"
      >
        {{ nftAddress }}
        <van-image
          fit="contain"
          :src="require('@/images/home/copy.png')"
          class="abs"
          width="22"
          style="top: 5px"
          @click="copy"
        ></van-image>
        <input
          type="text"
          :value="nftAddress"
          readonly="readonly"
          class="address abs"
          style="
            opacity: 0;
            z-index: -1;
            width: 1px;
            position: fixed;
            bottom: -100px;
          "
        />
      </div>
      <div style="overflow: hidden; width: 100%" class="pb30 mt30">
        <div class="flex_between flex_items plr10">
          <span class="f15" style="color: #666"
            >{{ List.length }}&nbsp;NFT</span
          >
          <van-image
            fit="contain"
            :src="src"
            class=""
            width="22"
            @click="changecol"
          ></van-image>
        </div>
        <div
          v-if="col == 1 && List.length != 0"
          class="container-water-fall case plr10 mt20 pb30"
        >
          <waterfall
            :col="col"
            :gutterWidth="20"
            :data="List"
            :lazyDistance="200"
            @loadmore="loadmore"
          >
            <template>
              <div
                class="case_item cell-item bgwhite"
                v-show="List.length !== 0"
                v-for="(item, index) in List"
                :key="index"
                style="border-radius: 4px; margin-top: 1vw"
              >
                <div class="imgs pos" style="width: 100%">
                  <img
                    v-if="item.videoUrl == null"
                    lazy-load
                    :src="item.photoThumbnailUrl"
                    class="image"
                    style="width: 100%"
                  />
                  <img
                    v-if="item.videoUrl != null"
                    lazy-load
                    :src="item.gifUrl"
                    class="image"
                    style="width: 100%"
                  />
                  <img
                    v-if="item.videoUrl != null"
                    class="abs"
                    :src="require('@/images/trending/play.png')"
                    style="right: 3px; top: 3px; width: 24px"
                  />
                </div>
              </div>
            </template>
          </waterfall>
        </div>
        <!-- 2 -->
        <div v-if="col == 2 && List.length != 0" class="two">
          <div class="container-water-fall case plr10 mt20">
            <waterfall
              :col="col"
              :gutterWidth="20"
              :data="List"
              :lazyDistance="200"
              @loadmore="loadmore"
            >
              <template>
                <div
                  class="case_item cell-item bgwhite"
                  v-show="List.length !== 0"
                  v-for="(item, index) in List"
                  :key="index"
                  style="border-radius: 4px; margin-top: 1vw"
                >
                  <div class="imgs pos" style="width: 100%">
                    <img
                      v-if="item.videoUrl == null"
                      lazy-load
                      :src="item.photoThumbnailUrl"
                      class="image"
                      style="width: 100%"
                    />
                    <img
                      v-if="item.videoUrl != null"
                      lazy-load
                      :src="item.gifUrl"
                      class="image"
                      style="width: 100%"
                    />
                    <img
                      v-if="item.videoUrl != null"
                      class="abs"
                      :src="require('@/images/trending/play.png')"
                      style="right: 3px; top: 3px; width: 24px"
                    />
                  </div>
                </div>
              </template>
            </waterfall>
          </div>
        </div>
        <div
          v-if="List.length == 0"
          class="flex_center flex_items"
          style="height: 250px"
        >
          <span class="f16" style="color: #9b9b9b">No NFT Created</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      page: 1,
      nftAddress: "",
      List: [],
      col: 2,
      src: require("@/images/home/col1.png"),
    };
  },
  mounted() {
    window.document.body.style.backgroundColor = "#000";
    this.getData();
  },
  methods: {
    getData() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .get(this.HOST + "/nft/posts", {
          appVersion: "1.0",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          page: this.page,
          pageSize: 20,
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
          type: 1,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            if (res.data.list.length == 0) {
              Toast("No more data");
              return false;
            }
            this.nftAddress = res.data.nftAddress;
            res.data.list.forEach((item) => {
              item.images.forEach((item2) => {
                this.List.push(item2);
              });
            });
          } else {
            Toast(res.msg);
          }
          this.isLoading = false;
        });
    },
    changecol() {
      if (this.col == 1) {
        this.col = 2;
        this.src = require("@/images/home/col2.png");
      } else {
        this.col = 1;
        this.src = require("@/images/home/col1.png");
      }
    },
    copy(txt) {
      var copyobject = document.getElementsByClassName("address")[0];
      copyobject.select();
      document.execCommand("Copy");
      Toast("Copied");
    },
    loadmore() {
      this.page += 1;
      this.getData();
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#000";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
};
</script>
<style scoped>
.two .vue-waterfall-column {
  padding: 2px;
  width: 46.2vw !important;
}
.mynft {
  /* position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000 !important; */
}
</style>